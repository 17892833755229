import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { NotFoundComponent } from "UI/NotFoundComponent"

const NotFoundPage = (pageContext) => (
  <Layout pageContext={pageContext}>
    <SEO title="404: Not found" />
    <NotFoundComponent />
  </Layout>
)

export default NotFoundPage
