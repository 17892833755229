import React from "react"
import { NotFoundComponentContainer } from "./style"

const NotFoundComponent = () => {
  return (
    <NotFoundComponentContainer>
      <h1>404</h1>
      <h4>NOT FOUND</h4>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </NotFoundComponentContainer>
  )
}

export default NotFoundComponent
