import styled from "styled-components"
import { device } from "breakpoints"

export const NotFoundComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.primaryColor};
  height: 600px;
  max-width: 1660px;
  margin: 0 auto;

  h1 {
    color: ${(props) => props.theme.secondaryColor};
    font-size: 8rem;
    @media ${device.desktop} {
      font-size: 15rem;
    }
  }
  h4 {
    color: ${(props) => props.theme.secondaryColor};
    font-size: 1.5rem;
    @media ${device.desktop} {
      font-size: 3rem;
    }
  }
  p {
    color: ${(props) => props.theme.secondaryColor};
    font-size: 1rem;
    margin-top: 30px;

    @media ${device.desktop} {
      font-size: 1.2rem;
    }
  }
`
